
// VERY IMPORTANT
// This file should never have any imports other than vue. This is used inside core libraries.

// VERY VERY IMPORTANT
// Never rename/remove exports as it will break components using this file. Any new addition or refactor must be thoroughly considered before being done

import { ref } from "vue";

declare global {
    interface Window {
        __navBarData: {
            isFlutter: boolean;
            isDeveloper: boolean;
        };
    }
}

const mediaQueries = {
    "isPWA": window.matchMedia("only screen and ((display-mode: standalone) or (display-mode: fullscreen))"),
    "isMobile": window.matchMedia("screen and (pointer: coarse) and (orientation: portrait) and (max-width: 600px), screen and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)"),
    "isTablet": window.matchMedia("screen and (pointer: coarse) and (orientation: portrait) and (min-width: 600px) and (max-width: 1024px), screen and (pointer: coarse) and (orientation: landscape) and (min-height: 600px) and (max-height: 1024px)"),
};

const devTestSites = [
    "https://dev.omega365.com",
    "https://dev-nt.omega365.com",
];

function matchQuery(name: keyof typeof mediaQueries): boolean {
    // return window.matchMedia(mediaQueries[name]).matches;
    return mediaQueries[name].matches;
}

// Used to ensure that non mobile devices with touchscreens do not get mobile layouts
const pcDeviceOs = (() => {
    let userAgent = navigator.userAgent || navigator.vendor;
    try {
        if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
            return 'MacOS';
        }
        if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
            return 'Windows';
        }
        if (/Linux/.test(userAgent)) {
            return 'Linux';
        }
        return '';
    } catch {
        return '';
    }
})();



/* serverData */

export const serverData = window.__navBarData ?? {};

export const isFlutter = ref<boolean>(!!serverData.isFlutter);
export const isDeveloper = ref<boolean>(!!serverData.isDeveloper);

/* media query */

export const isPWA = ref<boolean>(matchQuery("isPWA"));
export const isMobile = ref<boolean>(matchQuery("isMobile"));
export const isTablet = ref<boolean>(!pcDeviceOs && matchQuery("isTablet"));
export const isMobileOrTablet = ref<boolean>(!pcDeviceOs && (isMobile.value || isTablet.value));

/* other */

export const isDev = ref<boolean>(devTestSites.includes(window.origin));
export const isDevTest = ref<boolean>(devTestSites.includes(window.origin));
